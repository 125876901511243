<template lang="pug">
Layout
  Header
    div(:style="{ marginRight: '60px', display: 'flex'}")
      div(class="comercial-info-container")
        app-text(
          :customStyle="{width: '100%', margin: '5% 0'}"
          :content="seller",
          className="small-light inside-container")
        app-text(
          content="Comercial",
          className="nano-light inside-container"
          :customStyle="{width: '100%'}")
      app-text(
        @click="exitClient"
        :customStyle="{marginTop: '10%'}"
        className="small-error link"
        content="Cerrar")
  Layout
    draggable-div(
      v-if="is_in_call"
      :reset="resetDraggableDivFlag"
      :pointOrigin="{originX, originY}"
      v-on:reset-true="resetDraggableDiv(false)")
      template(slot="header")
        a-button(
          type="primary"
          size="small"
          @click="clickDragButton") Arrastrar
        a-button(
          size="small",
          @click="minimize()"
          :style="isMinimize ? {backgroundColor:'var(--primary-color) !important',color:'#fafafa'} : {}"
          ) Minimizar
      template(slot="main")
        JitsiVideoCall(
          v-on:call_closed="closeDraggable",
          :room_name="room_name",
          :user_is_moderator="false",
          :username="username",
          :minimized="minimized")
    a-button(
      type="primary"
      icon="camera"
      size="small"
      :style="{width: '40px', margin: '1% 2% 0 auto'}"
      @click="resetDraggableDiv(true)")
          a-icon(
            type="retweet")
    div(
      v-if="!endShowRoom && viewerItem.url",
      :style="{ flexWrap: 'wrap', margin: '30px auto 0', padding: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '95%', height: '115.04px', background: '#FFF' }"
    )
      app-h2(
        :content="viewerItem.serie",
        className="regular",
        :customStyle="{ margin: '0' }"
      )
      app-text(
        :customStyle="{ margin: '0' }"
        :content="viewerItem.description")
      invite-form
      a-tabs(
        :active-key="viewerItem.media",
        :key="viewerItem.media"
        :tabBarStyle="{ display: 'none' }",
        :style="{ width: '100%', marginTop: '2%', background:'var(--gray-color)'}"
      )
        a-tab-pane(tab="Imagen", key="image")
          AppImageHd(
            :customStyle="{ objectFit: 'scale-down', cursor: 'pointer' , maxHeight: '70vh', width: '100%' }",
            :url="viewerItem.url"
            :key="viewerItem.url"
          )
        a-tab-pane(tab="Video", key="video")
          app-video(
            :customStyle="{maxHeight: '70vh'}"
            :controlled="videoControl",
            ref="video",
            :width="1000",
            :height="700",
            :key="viewerItem.url"
            :idVideo="viewerItem.url")
    div(
      v-else
      :style="{ margin: '30px auto 0', padding: '30px', alignItems: 'center', justifyContent: 'center', width: '95%', height: '160 px', background: '#FFF' }"
    )
      AppH2(
        content="Bienvenido a "
        className="regular-container")
      br
      AppH2(
        content="The Digital ShowRooms"
        className="regular-color")
      br
      AppH2(
        content="En unos instantes recibirá imágenes y vídeos en alta definición."
        className="regular-container")
</template>
<script>
//import atoms components
import AppText from "@/components/atoms/AppText.vue";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import AppVideo from "@/components/atoms/AppVideo.vue";
import AppImageHd from "@/components/atoms/AppImageHd.vue";

// import organisms components
import Layout from "@/components/organisms/Layout.vue";
import Header from "@/components/organisms/Header.vue";

//Import Api url
import { WEBSOCKET_URL } from "@/common/config.js";

//import components to Jitsi Video Call
import DraggableDiv from "@/components/utils/DraggableDiv";
import JitsiVideoCall from "@/components/utils/JitsiVideoCall";

//Vuex imports
import { LOGOUT } from "@/store/actions.type";
import { mapGetters } from "vuex";

//Import custom components from this component
import InviteForm from "@/components/customComponents/ShowRoomClient/InviteForm.vue";

//Import jwt service
import JwtService from "@/common/jwt.service";

//color change service
import colorService from "@/common/color.service";

//Import validate exit endpoint
import { SalesRoomService } from "@/common/api.service.js";

export default {
  name: "ShowRoomClient",
  components: {
    Layout,
    Header,
    AppH2,
    AppButton,
    AppText,
    AppVideo,
    AppImageHd,
    DraggableDiv,
    JitsiVideoCall,
    InviteForm
  },
  data: function() {
    return {
      viewerItem: {
        media: "image",
        url: "",
        serie: "Serie inicial",
        description: ""
      },
      isMinimize: false,
      videoControl: true,
      minimized: false,
      is_in_call: true,
      username: "",
      seller: "",
      endShowRoom: false,
      originX: undefined,
      originY: undefined,
      resetDraggableDivFlag: false
    };
  },
  computed: {
    ...mapGetters(["getThemeName"]),
    room_name() {
      return (
        "STN-SHOWROOM-" +
        this.$session.get("ShowRoomClient").id +
        "-" +
        this.$session
          .get("ShowRoomClient")
          .scheduled_for.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")
      );
    }
  },
  async beforeMount() {
    await colorService.changeTheme(this.getThemeName);
  },
  updated() {
    if (this.endShowRoom) {
      this.endShowRoomClient();
    }
  },
  created() {
    //Create a connection with backend, sending the id of the room

    this.username = this.$session.get("user").name;
    this.seller =
      this.$session.get("ShowRoomClient").seller.name || "Comercial";

    const { id, showing_content } = this.$session.get("ShowRoomClient");

    if (showing_content) {
      const { media, url, serie } = JSON.parse(showing_content);
      if (media && url && serie) {
        this.viewerItem.media = media;
        this.viewerItem.url = url;
        this.viewerItem.serie = serie;
      }
    }
    this.$connect(
      `${WEBSOCKET_URL}/salesrooms_client/${id}/?token=${JwtService.getToken()}`,
      {
        format: "json"
      }
    );
    // Message Listener
    this.$options.sockets.onmessage = event => {
      let data = JSON.parse(event.data);
      if (
        data.type === "video_message" &&
        this.viewerItem.media === "video" &&
        this.viewerItem.url === data.video
      ) {
        if (data.action === "control-change") {
          this.videoControl = data.controlValue;
        }
        if (!this.videoControl) return;
        if (data.action === "pause" || data.action === "buffering") {
          this.$refs.video.pauseVideo(data.time);
        } else if (data.action === "play") {
          this.$refs.video.playVideo(data.time);
        } else if (data.action === "setvolume") {
          this.$refs.video.setVolume(data.volume);
        }
      } else if (data.type == "product_message") {
        const { product, media, url, serie, description } = data;

        if (product === "Finish ShowRoom") {
          this.endShowRoom = true;
          return;
        }
        this.viewerItem.media = media;
        this.viewerItem.url = url;
        this.viewerItem.serie = serie;
        this.viewerItem.description = description;
        this.$message.info("Archivo recibido");
      }
    };
  },
  methods: {
    clickDragButton(e) {
      if (!this.originX && !this.originY) {
        this.originX = e.clientX;
        this.originY = e.clientY;
      }
    },
    resetDraggableDiv(value) {
      this.resetDraggableDivFlag = value;
    },
    minimize() {
      this.minimized = !this.minimized;
      this.isMinimize = !this.isMinimize;
    },
    closeDraggable() {
      this.is_in_call = false;
    },
    exitClient() {
      SalesRoomService.attendantExit(this.$session.get("ShowRoomClient").id);
      this.$store.dispatch(LOGOUT);
      this.$session.destroy();
      this.$router.push({
        name: "LoginClient",
        params: { finishedShowRoom: false }
      });
    },
    endShowRoomClient() {
      SalesRoomService.attendantExit(this.$session.get("ShowRoomClient").id);
      this.$store.dispatch(LOGOUT);
      this.$session.destroy();
      this.$router.push({
        name: "LoginClient",
        params: { finishedShowRoom: true }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.comercial-info-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
}
</style>
